const supportEmail = "vr@mhssz.hu";

export default {
    hu: {
        "login": "Belépés",
        "password": "Jelszó",
        "error": "Hiba, kérlek írj a " + supportEmail + " címre.",
        "error404": "Érvénytelen fiók és/vagy jelszó.",
        "error406": "Zárolt fiók, kérlek írj a " + supportEmail + " címre, hogy feloldjuk a zárolást.",
        "error412": "Inaktív fiók, kérlek aktiváld a fiókot (ment róla e-mail) vagy írj a " + supportEmail + " címre.",
        "forgottenPassword": "Elfelejtett jelszó",
        "password-invalid": "Érvénytelen jelszó",

        "add": "Regisztráció",
        "edit": "Fiók szerkesztése",
        "save": "Mentés",

        "invalid-fields": "Érvényelen mezők",
        "invalid-fields-explanation": "Az alábbi mezők tartalma még nem megfelelő, a regisztrációt csak akkor tudod beküldeni, ha ezeket az adatokat helyesen megadtad.",
        "existing-name": "Ez a fióknév már foglalt. Ha korábban regisztráltál egy ilyen nevű fiókot, kérlek használd az elfelejtett jelszó funkciót vagy írj a " + supportEmail + " címre.",
        "close": "bezár",

        "principalName": "Fiók",
        "principalName-invalid": "Legalább 3, legfeljebb 50 karakter.",
        "emailAddress": "E-mail cím",
        "emailAddress-invalid": "Érvénytelen cím.",
        "credentials": "Jelszó",
        "credentials-invalid": "Legalább 8 karakter.",
        "verification": "Megerősítés",
        "verification-invalid": "A két jelszó nem azonos.",
        "fullName": "Név",
        "fullName-invalid": "Legalább 5 karakter.",
        "address": "Lakcím",
        "address-invalid": "Legalább 12 karakter.",

        "account-fields": "Fiók adatok",
        "account-fields-explanation": "Az itt megadott fióknevet és jelszót kell majd megadnod a belépésnél. A fióknév nem látszik a többi felhasználó számára. Kérlek ne hozz létre több fiókot egy személyhez, ha nem tudsz belépni egy korábban létrehozott fiókkal, írj a " + supportEmail + " email címre.",
        "required-fields": "Kötelező adatok",
        "required-fields-explanation": "Ezeket az adatokat mindenkinek meg kell adnia.",
        "championship-fields": "Bajnokság",
        "championship-fields-explanation": "Ezeket az adatokat az egyesületi tagoknak, illetve a bajnokságban résztvevő versenyzőknek kell megadnia.",
        "birth-fields": "Születési adatok",
        "birth-fields-explanation": "Ezeket az adatokat nyomtatványok készítéséhez használja a rendszer. Jelenleg csak kiskorú résztvevőknek.",
        "invoicing-fields": "Számlázási adatok",
        "invoicing-fields-explanation": "Ezeket az adatokat akkor kell megadni, ha az események díjáról készült számlát nem a versenyző nevére kéred kiállítani.",

        "gender": "Nem",
        "MALE": "férfi",
        "FEMALE": "nő",
        "birthPlace": "Születési hely",
        "birthPlace-invalid": "Legalább 2 karakter",
        "birthDate-invalid": "Érvénytelen dátum, formátum: ÉÉÉÉ.HH.NN",
        "birthDate": "Születési dátum",
        "mothersName": "Anyja neve",
        "mothersName-invalid": "Legalább 5, legfeljebb 60 karakter",
        "mothersBirthDate": "Anyja születési dátuma",
        "mothersBirthDate-invalid": "Érvénytelen dátum, formátum: ÉÉÉÉ.HH.NN",
        "mothersMothersName": "Anyai nagyanya neve",
        "mothersMothersName-invalid": "Legalább 5, legfeljebb 60 karakter",
        "classification": "Besorolás",
        "cardNumber": "MHSSZ kártyaszám",
        "cardNumber-invalid": "A kártyaszámot aaaXXX formában kell megadni, például: GSE258",
        "medicalExpiration": "Sportorvosi lejárata",
        "medicalExpiration-invalid": "Érvénytelen dátum, formátum: ÉÉÉÉ.HH.NN",
        "club": "Egyesület",
        "invoicingName": "Számlázási név",
        "invoicingName-invalid": "Legalább 5, legfeljebb 60 karakter",
        "invoicingAddress": "Számlázási cím",
        "invoicingAddress-invalid": "Legalább 5, legfeljebb 120 karakter",
        "invoicingVATID": "Adószám",
        "invoicingVATID-invalid": "Legalább 5, legfeljebb 20 karakter",
        "tshirtSize": "Polóméret",
        "consent-title": "Elfogadó nyilatkozat",
        "consent-content": "A regisztráció beküldésével elfogadod, hogy\n" +
            "az MHSSZ köteles személyes és egyéb adataidat az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény rendelkezései szerint kezelni,\n" +
            "a sportról szóló 2004. évi I. törvény 3. § (8) bekezdésének értelmében a versenyengedély kiállítása érdekében kezelt adatok statisztikai célra felhasználhatók, és statisztikai célú felhasználásra - személyazonosításra alkalmatlan módon - átadhatók.\n" +
            "Jelen regisztrációval hozzájárulsz ahhoz, hogy személyes és egyéb adataidat az MHSSZ versenyengedélyek kiállítására, közvetlen kapcsolatfelvételre, versenyszervezésre, versenyeredmények nyilvántartására, publikálására, a sportszövetség alapszabályában megfogalmazott célok elérésére és a törvényi előírások teljesítése érdekében felhasználja.",
        "phoneNumber": "Telefonszám",
        "phoneNumber-invalid": "Legalább 9, legfeljebb 15 karakter",
        "amateur": "amatőr",
        "professional": "profi",
        "consent": "Elfogadó nyilatkozat",

        "settings": "Beállítások",
        "showRegistration": "Előnevezés megjelenítése.",
        "showRegistration-explanation": "Ha engedélyezve van, a többi versenyző látni fogja az esemény oldalán, hogy neveztél.",

        "reset-start-title": "Jelszó megújítás",
        "reset-start-explanation": "A megújításhoz kérlek add meg fiókod nevét és születési dátumod, majd klikkelj a Megújítás gombra. Ha az adatok megfelelőek, kapni fogsz egy e-mailt, amelyben benne lesz a megújításhoz szükséges titkos kód.",
        "reset-start-button": "Megújítás",

        "reset-start-success": "Sikeresen megkezdődött a megújítás.",
        "reset-start-success-explanation": "A jelszó megújítása megkezdődött, a rendszer küldeni fog egy e-mailt a megújításhoz szükséges titkos kóddal. A titkos kód 8 órán át érvényes, utána újat kell kérned.",
        "reset-start-error": "Nem sikerült elindítani a megújítást.",

        "password-change-title": "Jelszóváltozatás",
        "password-change-explanation": "Kérlek add a régi és az új jelszót.",
        "password-change-button": "Végrehajtás",

        "add-success": "Sikeres regisztráció",
        "add-success-explanation": "Sikeresen regisztráltál a versenyrendszerbe. A fiókot használat előtt aktiválnod kell. Hamarosan kapsz egy e-mailt a vr@mhssz.hu címről, amelyben benne lesz az aktivációhoz szükséges hivatkozás.",

        "reset-finish-title": "Új jelszó megadása",
        "reset-finish-explanation": "Kérlek add az új jelszót.",
        "reset-finish-button": "Beállítás",

        "secret": "Titkos kód",
        "oldPassword": "Régi jelszó",
        "newPassword": "Új jelszó",

        "reset-finish-success": "Sikeres jelszó megújítás",
        "reset-finish-success-explanation": "Az új jelszó beállítása sikerült, erre a gombra kattintva be tudsz lépni a rendszerbe:",
        "reset-finish-error": "Az új jelszó beállítása nem sikerült, kérlek vedd fel velünk a kapcsolatot a " + supportEmail + " címen.",

        "activate-title": "Fiók aktiválása",
        "activate-in-progress": "A fiók aktiválása folyamatban.",
        "activate-success": "A fiók aktiválása sikerült, erre a gombra kattintva be tudsz lépni a rendszerbe:",
        "activate-error": "A fiók aktiválása nem sikerült, kérlek vedd fel velünk a kapcsolatot a " + supportEmail + " címen.",

        "password-change": "Jelszó változtatás",
        "password-change-error": "Nem sikerült megváltoztatni a jelszót.",
        "password-change-success": "Sikeres jelszó változtatás",
        "password-change-success-explanation": "Az új jelszó beállítása megtörtént. A következő bejelentkezés alkalmával a most megadott új jelszót kell használnod.",

        "expired-session": "Lejárt munkamenet",
        "expired-session-explanation": "A munkamenet lejárt, ezért újból be kell jelentkezned: "
    },
    en: {
        "login": "Login",
        "password": "Password",
        "error": "Error, please write an e-mail to " + supportEmail + ".",
        "error404": "Invalid login or password.",
        "error406": "Account has been locked, please write an e-mail to " + supportEmail + ", so we can unlock it.",
        "error412": "Account has not been activated, yet. Please activate the account using the link received via e-mail. If no link received, please write an e-mail to " + supportEmail + ".",
        "forgottenPassword": "Forgotten password",
        "password-invalid": "Invalid password",

        "add": "Register account",
        "edit": "Edit account",
        "save": "Save",

        "invalid-fields": "Invalid fields",
        "invalid-fields-explanation": "The content of the following field(s) does not meet requirements, in order to be able to register you need to correct them.",
        "existing-name": "This account name has already been used. If you have previously registered that account, please use the Forgotten password function to gain access to it or write an e-mail to " + supportEmail + ". But PLEASE DO NOT REGISTER MULTIPLE TIMES!!!",
        "close": "Close",

        "principalName": "Account",
        "principalName-invalid": "Minimum character length is 3 characters, maximum is 50.",
        "emailAddress": "E-mail address",
        "emailAddress-invalid": "Invalid e-mail address.",
        "credentials": "Password",
        "credentials-invalid": "Minimum character length is 8 characters.",
        "verification": "Verification",
        "verification-invalid": "The two passwords differ from each other.",
        "fullName": "Name (full)",
        "fullName-invalid": "Minimum character length is 5 characters.",
        "address": "Address",
        "address-invalid": "Minimum character length is 12 characters.",

        "account-fields": "Account core data",
        "account-fields-explanation": "Account name, password provided here will be used during the login process. Account name is private, not visible to other users. We kindly ask not to register multiple times for the same climber. If you are unable to login using the known credentials, and the Forgotten password function cannot help, please write an e-mail to " + supportEmail + ".",
        "required-fields": "Mandatory fields",
        "required-fields-explanation": "Required fields must be provided in order to be able to save data.",
        "championship-fields": "Championship",
        "championship-fields-explanation": "This section is for members of Hungarian Climbing Clubs or those competing in the Hungarian Championship.",
        "birth-fields": "Additional birth data",
        "birth-fields-explanation": "Data provided in this section is only used to generate certain documents such as school leave applications or absence certificates. Currently for competitors under the age of 18, only.",
        "invoicing-fields": "Billing info",
        "invoicing-fields-explanation": "You have to use this section only, if you want to specify an alternative billing name or address, differing from that of the competitor’s own ones.",

        "gender": "Gender",
        "MALE": "male",
        "FEMALE": "female",
        "birthPlace": "Place of birth",
        "birthPlace-invalid": "Minimum character length is 2 characters.",
        "birthDate-invalid": "Invalid date format. Valid format is YYYY.MM.DD",
        "birthDate": "Date of birth",
        "mothersName": "Mother’s name",
        "mothersName-invalid": "Minimum character length is 5, maximum is 60 characters",
        "mothersBirthDate": "Mother’s date of birth",
        "mothersBirthDate-invalid": "Invalid date format. Valid format is YYYY.MM.DD",
        "mothersMothersName": "Name of mother’s mother",
        "mothersMothersName-invalid": "Minimum character length is 5, maximum is 60 characters",
        "classification": "Classification",
        "cardNumber": "MHSSZ card number",
        "cardNumber-invalid": "The valid card number format is aaaXXXX, for example: GSE0258",
        "medicalExpiration": "Medical exam expiration",
        "medicalExpiration-invalid": "Invalid date format. Valid format is YYYY.MM.DD",
        "club": "Club",
        "invoicingName": "Invoicing name",
        "invoicingName-invalid": "Minimum character length is 5, maximum is 60 characters",
        "invoicingAddress": "Invoicing address",
        "invoicingAddress-invalid": "Minimum character length is 5, maximum is 120 characters",
        "invoicingVATID": "TAX Nr.",
        "invoicingVATID-invalid": "Minimum character length is 5, maximum is 20 characters",
        "tshirtSize": "T-shirt size",
        "consent-title": "Registration consent",
        "consent-content": "By registering to this service you accept, that\n" +
            "the Hungarian Mountaineering & Sport Climbing Federation (MHSSZ) is obliged to handle all personal and other data provided by you in accordance with Law CXII of 2011 on the right to self-determination as regards information and freedom of information (GDPR),\n" +
            "according to Act I of 2004 on Sport, paragraph 3. § (8) data provided for the purpose of issuing a competition license can be used for or - after proper anonymization- can be handed over to third parties for statistical purposes.\n" +
            "By registering for current services you agree to MHSSZ using personal and other data provided by you for the purpose of issuing competition license, direct contact, organization of competitions, recording and publication of competition results, to achieve the goals set out in the statutes of the sports association and to comply with the legal requirements.",
        "phoneNumber": "Phone number",
        "phoneNumber-invalid": "Minimum character length is 9, maximum is 15 characters",
        "amateur": "amateur",
        "professional": "professional",
        "consent": "Declaration of consent",

        "settings": "Settings",
        "showRegistration": "Display name in pre-registrations.",
        "showRegistration-explanation": "If enabled, once you register to an event, your name will appear on the publicly available list of registered competitors of the given event.",

        "reset-start-title": "Password renewal",
        "reset-start-explanation": "If you want to start the password renewal process, please provide your account name and date of birth in the relevant fields and press the Renewal button. If the account name matches one in the database, and the date of birth info provided now matches that of the account, the system will send a message with the secret code that is necessary to renew the account’s password to the e-mail address of the account.",
        "reset-start-button": "Renewal",

        "reset-start-success": "Renewal process has successfully been started.",
        "reset-start-success-explanation": "Password renewal process has been started. The system will send out the secret code necessary to renew the password to the e-mail address of the account. Please note, this secret code is valid for 8 hours, only. If you fail to use it within the given deadline, you must request a new one. If you do not receive the e-mail within a reasonable time, please check your spam folder, etc. first. If you do not find the message at all, please write an e-mail to " + supportEmail + ".",
        "reset-start-error": "Renewal process could not be started. Check the info provided and try again. If you fail, please write an e-mail to " + supportEmail + ".",

        "password-change-title": "Changing password",
        "password-change-explanation": "Please provide the old and new password.",
        "password-change-button": "Commit",

        "add-success": "Successful registration",
        "add-success-explanation": "You have successfully registered in the competition system (VR). Before you can use your account, you must activate it. You should soon receive an e-mail from vr@mhssz.hu with the necessary link.",

        "reset-finish-title": "New password form",
        "reset-finish-explanation": "Please provide your new password.",
        "reset-finish-button": "Commit",

        "secret": "Secret code",
        "oldPassword": "Old password",
        "newPassword": "New password",

        "reset-finish-success": "Successful password renewal",
        "reset-finish-success-explanation": "The new password has been set. By clicking the provided button you can sign in using the new credentials:",
        "reset-finish-error": "Setting of the new password has failed. Please write an e-mail to " + supportEmail + ".",

        "activate-title": "Account activation",
        "activate-in-progress": "Activation of the account is in progress.",
        "activate-success": "Account activation has successfully been completed. By clicking the provided button you can sign in using your credentials:",
        "activate-error": "Activation has failed. Please write an e-mail to " + supportEmail + ".",

        "password-change": "Change password",
        "password-change-error": "Error! Password has NOT been changed.",
        "password-change-success": "Successful change of password",
        "password-change-success-explanation": "The new password has been set. In order to log in from now on you must use the new password.",

        "expired-session": "Expired session",
        "expired-session-explanation": "The session has expired, therefore you must log in, again: "
    }
};